import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Formik, Field, ErrorMessage, Form,
} from 'formik';
import { useTrail, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import { ContactForm } from './styles';

// Validation schema
const contactFormSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name.'),
  lastName: Yup.string().required('Please enter your last name.'),
  email: Yup.string()
    .email()
    .required('Please enter a valid email.'),
  subject: Yup.string().required('Please enter a subject.'),
  message: Yup.string().required('Please enter a message.'),
});

// Encode form data
function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const items = [
  {
    labelTitle: 'First Name:',
    fieldName: 'firstName',
    labelFor: 'first_name',
    component: 'input',
    placeholder: 'Sam',
  },
  {
    labelTitle: 'Last Name:',
    fieldName: 'lastName',
    labelFor: 'last_name',
    component: 'input',
    placeholder: 'Smith',
  },
  {
    labelTitle: 'Email:',
    fieldName: 'email',
    labelFor: 'user_email',
    component: 'input',
    placeholder: 'youremail@domain.com',
    value: '',
  },
  {
    labelTitle: 'Subject:',
    fieldName: 'subject',
    labelFor: 'message_subject',
    component: 'input',
    placeholder: 'Type your subject here...',
  },
  {
    labelTitle: 'Message:',
    fieldName: 'message',
    labelFor: 'message_body',
    component: 'textarea',
    placeholder: 'Type your message here...',
  },
];

const Contact = () => {
  const [on, setOn] = useState(false);
  const [formItems, setFormItems] = useState([]);

  const fadeInTrail = useTrail(formItems.length, {
    from: { transform: `translate3d(-10%,0,0)`, opacity: 0 },
    opacity: on ? 1 : 0,
    transform: on ? `translate3d(0,0,0)` : `translate3d(-10%,0,0)`,
  });

  useEffect(() => {
    setFormItems(items);
  });

  return (
    <ContactForm>
      <Waypoint bottomOffset="35%" onEnter={() => setOn(true)} />
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
        }}
        onSubmit={(values) => {
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': 'contact',
              ...values,
            }),
          }).then(() => setTimeout(() => {
            window.location.href = '/thankyou';
          }, 1000));
        }}
        validationSchema={contactFormSchema}
        render={() => (
          <Form
            id="contact_form"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="firstName" />
            <input type="hidden" name="lastName" />
            <input type="hidden" name="email" />
            <input type="hidden" name="subject" />
            <input type="hidden" name="message" />
            {fadeInTrail.map((props, i) => (
              <animated.label
                style={props}
                key={i}
                htmlFor={formItems.labelFor}
              >
                {formItems[i].labelTitle}
                <Field
                  name={formItems[i].fieldName}
                  placeholder={formItems[i].placeholder}
                  component={formItems[i].component}
                  id={formItems[i].labelFor}
                />
                <ErrorMessage name={formItems[i].fieldName} component="div" />
              </animated.label>
            ))}
            <button type="submit">Submit</button>
          </Form>
        )}
      />
    </ContactForm>
  );
};

export default Contact;
