import React from 'react';
import Heading from 'components/Heading';
import ContactForm from 'components/Forms/Contact';
import { Wrapper, Content } from './styles';

const Contact = () => (
  <Wrapper>
    <Heading text="Contact" />
    <Content>
      <ContactForm />
    </Content>
  </Wrapper>
);

export default Contact;
