import React from 'react';
import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Background from 'components/Background';
import Contact from 'components/Sections/Contact';

const ContactPage = () => (
  <Layout>
    <Background bgType="grass">
      <SEO title="Contact" />
      <Contact />
    </Background>
  </Layout>
);

export default ContactPage;
