import styled from 'styled-components';

export const Wrapper = styled.section`
  display: block;
`;

export const Content = styled.div`
  padding-bottom: 3rem;
  max-width: 600px;
  margin: 0 auto;
`;
