import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'styles/media';

// Import images
import clouds from 'images/backgrounds/clouds.svg';
import grass from 'images/backgrounds/grass.png';
import bubbles from 'images/backgrounds/soap-bubbles.jpg';

const BackgroundWrapper = styled.div`
  &.clouds {
    background-image: url(${clouds}),
      linear-gradient(to bottom, white 50%, #b4f2ff 100%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @media ${media.small} {
      backgroud-size: contain;
      background-position: center bottom;
    }
  }
  &.bubbles {
    background: url(${bubbles}) no-repeat center bottom;
    background-size: cover;
  }
  &.grass {
    background-image: url(${grass});
    background-repeat: repeat-x;
    background-position: bottom left;
    background-size: 800px;
  }
`;

const Background = ({ children, bgType }) => (
  <BackgroundWrapper className={bgType}>{children}</BackgroundWrapper>
);

Background.propTypes = {
  children: PropTypes.node.isRequired,
  bgType: PropTypes.string.isRequired,
};

export default Background;
