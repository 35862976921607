import styled from 'styled-components';

export const ContactForm = styled.div`
  label {
    display: block;
    margin-bottom: 2rem;
    div {
      color: red;
      font-weight: 100;
      font-size: 0.75rem;
    }
  }
  input,
  textarea {
    width: 100%;
    height: 45px;
    padding: 0.5rem;
    display: block;
    border: 1px solid #ccc;
    &::placeholder {
      color: #999;
      font-style: italic;
    }
  }
  textarea {
    height: 200px;
  }
  button[type='submit'] {
    display: block;
    color: #fff;
    border: none;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
    background: #0889af;
    border-radius: 50px;
    transition: all 300ms;
    &:hover {
      cursor: pointer;
      background: #0aa8d6;
    }
  }
`;
